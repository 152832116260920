import { Alert, AlertTitle, Box } from "@mui/material";
import { Bundesland, fundingConditions } from "../../appState/legalSignals";
import { activePage } from "../../appState/appViewSignals";

const SummaryLegal: React.FC = () => {
    return <>
        {/* Direktzahlungen */}
        <Box mb={2}>
            { fundingConditions.value.directPayments ?(
                <Alert severity="success">
                    <AlertTitle>Du hast evtl. Anspruch auf Direktzahlungen</AlertTitle>
                    Dein Agroforstsystem erfüllt alle Voraussetzungen nach der GAP (§4 Abs. 2 GAPDZV) und du hast evtl. Anspruch auf Direktzahlungen.
                </Alert>
            ) : (
                <Alert severity="warning">
                    <AlertTitle>Du hast keinen Anspruch auf Direktzahlungen</AlertTitle>
                    Dein Agroforstsystem erfüllt nicht alle Voraussetzungen nach der GAP (§4 Abs. 2 GAPDZV) und du hast keinen Anspruch auf Direktzahlungen.
                </Alert>
            )}
        </Box>

        {/* Ökoregelung */}
        <Box mb={2}>
            { fundingConditions.value.ecoRegulation ?(
                <Alert severity="success">
                    <AlertTitle>Du habst evtl. Anspruch auf Förderung nach Ökoregelung</AlertTitle>
                    Förderung nach Ökoregelung Agroforst kann beantragt werden, da alle Voraussetzungen erfüllt sind.
                </Alert>
            ) : (
                <Alert severity="warning">
                    <AlertTitle>Du hast keinen Anspruch auf Förderung nach Ökoregelung</AlertTitle>
                    Die Voraussetzungen für Förderung nach Ökoregelung Agroforst sind derzeit nicht erfüllt.
                </Alert>
            )}
        </Box>

        <Box mb={2}>
            <Alert severity="info">
                <AlertTitle>Zusätzliche Informationen</AlertTitle>
                Zusätzlich gelten Bestimmungen des Landes: {Bundesland.value}. <br />
                <a onClick={() => activePage.value = 'legal'} style={{cursor: 'pointer', textDecoration: 'underline'}}>
                    Informiere dich hier.
                </a>
                <br />
                Für die Umsetzung der rechtlichen Rahmenbedingungen sind die untere Landwirtschaftsbehörde (Förderung) bzw. 
                untere Naturschutzbehörde (Naturschutzrecht) verantwortlich. Wende dich bei Unklarheiten direkt an 
                die zuständige Behörde.
            </Alert>
        </Box>
        
        <Box mb={2}>
            <Alert severity="error">
                <AlertTitle>Rechtlicher Hinweis</AlertTitle>
                Die Berechnung der Förderbedingungen erfolgt auf Basis der gesetzlichen Regelungen von Juni 2024.
                Sowohl die Seite agroforst-planungstool.de als auch die Firma hydrocode GmbH stellen diese Informationen als 
                reine Orientierungshilfe zur Verfügung.
                Die Angaben sind ohne Gewähr und ersetzen keine rechtliche Beratung. 
                Förderansprüche müssen durch die zuständigen Behörden geprüft werden, bei Nicht-Gewährung ist hydrocode GmbH nicht haftbar.
            </Alert>
        </Box>
    </>
}

export default SummaryLegal;